import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const GeoMap = ({ nodes }) => {
    const mapRef = useRef(null);
    const mapInstance = useRef(null);

    useEffect(() => {
        if (!mapInstance.current) {
            // Initialize map instance
            mapInstance.current = L.map(mapRef.current, {
                scrollWheelZoom: true, // Enable scroll zoom
                dragging: true, // Enable dragging
                zoomControl: true, // Ensure zoom controls are available
                zoomSnap: 0.25, // Fine-grained zoom steps
            });

            // Add tile layer
            L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/">CARTO</a>',
                subdomains: 'abcd',
                maxZoom: 19,
            }).addTo(mapInstance.current);

            // Fit the map to California bounds
            const californiaBounds = [
                [32.5343, -120.5], // Southwest corner
                [42.0095, -114.1312], // Northeast corner
            ];
            mapInstance.current.fitBounds(californiaBounds, {
                padding: [20, 20], // Add padding for a better view
            });

            // Add GeoJSON layer for US states with grey borders
            fetch('https://raw.githubusercontent.com/PublicaMundi/MappingAPI/master/data/geojson/us-states.json')
                .then((response) => response.json())
                .then((geojson) => {
                    L.geoJSON(geojson, {
                        style: {
                            color: 'darkgray', // Grey border color
                            weight: 1.5,
                            fill: false, // Transparent fill
                        },
                    }).addTo(mapInstance.current);
                })
                .catch((error) => console.error('Failed to load GeoJSON:', error));
        }

        // Remove previous markers before adding new ones
        mapInstance.current.eachLayer((layer) => {
            if (layer instanceof L.Marker) {
                mapInstance.current.removeLayer(layer);
            }
        });

        // Helper function to determine marker color
        const getMarkerColor = (data) => {
            if (data.labels.includes('Participant')) {
                return 'blue';
            } else if (data.labels.includes('Exchange')) {
                return 'green';
            } else {
                return 'gray';
            }
        };

        // Add markers to the map
        const customOrder = [
            'Participant_ID',
            'Participant_Name_PrimaryOrganization',
            'Participant_Name_SubordinateOrganization',
            'DxF_Program_Category',
            'Type',
            'Sub_Type',
            'State',
            'State Abbr.',
            'County',
            'City',
            'City Clean',
            'ZIP_Code',
            'ZIP_code Clean',
            'Request_for_Information',
            'Information_Delivery',
            'Requests_for_Notifications_of_ADT_Events',
            'Org_Level',
        ];

        nodes.forEach((node) => {
            const { Geopoint } = node.data || {};
            const markerColor = getMarkerColor(node.data);

            if (Geopoint && Array.isArray(Geopoint)) {
                const customIcon = L.divIcon({
                    html: `<div style="background-color: ${markerColor}; width: 12px; height: 12px; border-radius: 50%; border: 2px solid lightblue;"></div>`,
                    className: '',
                    iconSize: [12, 12],
                    iconAnchor: [6, 6], // Center the icon
                });

                L.marker(Geopoint, { icon: customIcon })
                    .addTo(mapInstance.current)
                    .bindPopup(() => {
                        const tooltipContent = Object.entries(node.data)
                            .sort(([keyA], [keyB]) => {
                                const indexA = customOrder.indexOf(keyA);
                                const indexB = customOrder.indexOf(keyB);
                                if (indexA > -1 && indexB > -1) {
                                    return indexA - indexB;
                                } else if (indexA > -1) {
                                    return -1;
                                } else if (indexB > -1) {
                                    return 1;
                                }
                                return keyA.localeCompare(keyB);
                            })
                            .map(([key, value]) => `<b>${key}:</b> ${value || 'N/A'}`)
                            .join('<br>');
                        return `<b>Node Details</b><br>${tooltipContent}`;
                    });
            }
        });
    }, [nodes]);

    return <div ref={mapRef} style={{ width: '100%', height: '100%' }}></div>;
};

export default GeoMap;
