import { useAuthenticator } from "@aws-amplify/ui-react";
import { uploadData } from 'aws-amplify/storage';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { useContext, useState } from "react";

import MicroModal from 'micromodal';
import Modal from '../components/Modal/Modal';
import { SessionContext } from '../App.js';
import BugReportForm from "../ui-components/BugReportForm";


const BugReport = () => {

	const navigate = useNavigate();

	const { user } = useAuthenticator(context => [context.user]);
	// console.log(user)
	// let session = currentSession()
	const { session, isLoading } = useContext(SessionContext);

	const [logFile, setLogFile] = useState(null);

	function sanitizeS3Path(input) {
		// Remove any character that is not alphanumeric, dash, underscore, period, or slash
		return input.replace(/[^a-zA-Z0-9\-_.\/]/g, '');
	}

	const onBugReportSubmit = (fields) => {
		console.log("updating bug report: ", fields);
		fields.ExtraInfo = [user?.signInDetails.loginId];
		fields.UserIdToken = session?.tokens.idToken.toString();

		const log_file_path = sanitizeS3Path(`protected/${session.sub}/bug-${user?.signInDetails.loginId}-${new Date().toISOString()}.log`);
		fields.LogFilePath = log_file_path;

		const logContent = window.getLogEntries().join('\n');
		const blob = new Blob([logContent], { type: 'text/plain' });
		try {
			uploadData({
				path: log_file_path,
				// Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
				data: blob,
				options: {
					onProgress: ({ transferredBytes, totalBytes }) => {
						if (totalBytes) {
							console.log(
								`Upload progress ${Math.round((transferredBytes / totalBytes) * 100)} %`
							);
						}
					}
				}
			});
		} catch (error) {
			console.log('Error : ', error);
		}

		console.log("submitting form", fields);
		return fields;
	};
	// const [submitStatus, setSubmitStatus] = useState(0);

	const [modalParams, setModalParams] = useState({
		title: '',
		message: '',
		onClose: () => { },
	});

	const show_modal = (submitStatus) => {
		let close_func = () => { };
		if (submitStatus < 0) {
			setModalParams({
				title: 'Error!',
				message: `Error submitting bug report`
			});
		} else {
			setModalParams({
				title: 'Success!',
				message: 'Thank you! We received your submission and will review it shortly.'
			});
		}
		close_func = () => {
			navigate("/");
			window.scrollTo(0, 0);
		};

		MicroModal.show('modal-1', {
			// https://micromodal.vercel.app/#configuration
			onClose: submitStatus == 1 ? close_func : () => { console.log("closing modal"); },
		});
	};

	return (
		<div style={{
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%'
		}}>
			<Modal {...modalParams} />
			<BugReportForm
				onValidate={() => { }}
				onSubmit={(fields) => {
					return onBugReportSubmit(fields);
				}}
				onSuccess={() => {
					// setSubmitStatus(1)
					show_modal(1);
				}}
				onError={(err) => {
					// setSubmitStatus(-1)
					console.error(err);
					show_modal(-1);
				}}
			></BugReportForm>
		</div>
	);
};

export default BugReport;