

import React, { useEffect, useState } from 'react';
import Ogma from '@linkurious/ogma';

const ApiVisualization = () => {
  const [graphData, setGraphData] = useState(null); // Store graph data
  const [selectedNode, setSelectedNode] = useState(null); // Store selected node properties

  const API_BASE_URL =
    process.env.NODE_ENV === 'production'
      ? 'https://sbx.connectingforbetterhealth.com'
      : 'http://localhost:3001';

  const nodeUrlMap = {
    'VA Cerner': `${window.location.origin}/apis/Cerner`,
    'NextGen Healthcare': `${window.location.origin}/apis/NextGen`,
    'DxF Sandbox': `${window.location.origin}/apis/C4BH`,
  };

  useEffect(() => {
    // Fetch graph data from the backend
    fetch(`${API_BASE_URL}/api/load-api-graph-data`)
      .then((response) => response.json())
      .then((data) => {
        console.log('Graph data fetched from backend:', data);
        setGraphData(data);
      })
      .catch((error) => console.error('Error fetching graph data:', error));
  }, []);

  useEffect(() => {
    if (graphData) {
      initializeOgma(graphData);
    }
  }, [graphData]);

  const initializeOgma = (data) => {
    const ogma = new Ogma({
      container: 'graph-container',
    });

    ogma.setGraph({
      nodes: data.nodes.map((node) => ({
        id: node['~id'],
        attributes: {
          text: {
            content: node.name,
            size: 20,
            color: 'Black',
            font: 'Arial',
          },
          radius: node.name === 'DxF Sandbox' ? 30 : 15,
          color: 'blue',
        },
      })),
      edges: data.relationships.map(edge => {
        // Function to determine color based on api_type
        const getColorByApiType = (apiType) => {
            switch (apiType) {
                case 'Facilities': return 'orange';
                case 'Patients': return 'green';
                case 'Providers': return 'purple';
                default: return 'grey'; // Default color
            }
        };

        return {
            id: edge['~id'],
            source: edge['~start_node_id'],
            target: edge['~end_node_id'],
            attributes: {
              text: {
                content: edge.api_type, // Changed from `node.name` to use an object for more options
                size: 18, // Adjust text size as needed
                color: 'black', // Optional: Change text color
                font: 'Arial' // Optional: Change font type
            },
                color: getColorByApiType(edge.api_type), // Set color dynamically
                shape: {
                    head: 'arrow'
                },
                opacity: 0.55
            }
        };
    })
});

ogma.layouts.hierarchical({
  direction: 'RL',
  nodeDistance: 100,
  levelDistance: 200,
}).then(() => {

  ogma.view.locateGraph({
    margin: 50,
    duration: 500
  });
}).catch(console.error);
    // Handle node clicks
    ogma.events.on('click', (event) => {
      if (event.target && event.target.isNode) {
        const nodeId = event.target.getId();
        const nodeProperties = data.nodes.find((node) => node['~id'] === nodeId);

        if (nodeProperties) {
          setSelectedNode(nodeProperties); // Store selected node properties for display
        } else {
          console.error(`No properties found for node ID: ${nodeId}`);
        }
      }
    });
  };

  return (
    <div style={{ display: 'flex', height: '800px', fontFamily: 'Arial, sans-serif' }}>
      {/* Graph Container */}
      <div
        id="graph-container"
        style={{
          width: '75%',
          height: '100%',
          border: '1px solid #ddd',
          backgroundColor: '#f0f0f0',
        }}
      ></div>

      {/* Properties Panel */}
      <div
        id="properties-panel"
        style={{
          width: '25%',
          padding: '20px',
          backgroundColor: '#ffffff',
          borderLeft: '1px solid #ddd',
          overflowY: 'auto',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        {selectedNode ? (
          <div style={{ animation: 'fadeIn 0.5s' }}>
            <h3 style={{ color: '#333', borderBottom: '2px solid #007bff', paddingBottom: '5px' }}>
              Node Properties
            </h3>
            {/* <p><strong>ID:</strong> {selectedNode['~id']}</p> */}
            <p><strong>Name:</strong> {selectedNode.name}</p>
            <p><strong>Categories:</strong> {selectedNode.categories}</p>
            <p><strong>Protocols:</strong> {selectedNode.protocols}</p>
            <p><strong>Authentication:</strong> {selectedNode.authentication}</p>

            {/* Navigate to Endpoint Button */}
            {nodeUrlMap[selectedNode.name] && (
              <button
                style={{
                  backgroundColor: '#007bff',
                  color: '#ffffff',
                  padding: '10px 20px',
                  marginTop: '10px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  window.open(nodeUrlMap[selectedNode.name], '_blank'); // Open in a new tab
                }}
              >
                Go to Endpoint
              </button>
            )}
          </div>
        ) : (
          <div style={{ color: '#555', textAlign: 'center', marginTop: '50px' }}>
            <p>Click on a node to view its properties.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApiVisualization;
