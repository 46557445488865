// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RouteLogger from './data/RouteLogger';
import MainPage from './pages/MainPage';
import AdminPage from './pages/AdminPage';
//import Callback from './data/Callback';
import Mirth from './pages/Mirth';
import Networking from './pages/Networking';
import DxfRegistration from './pages/DxfRegistration';
import UseCase from './pages/useCase';
import SmileCDR from './pages/SmileCDR';
import Background from './pages/Background';
import OldMainPage from './pages/OldMainPage';
import DevPage from './pages/DevPage';
import Graph from './pages/Graph';
import FTP from './pages/FTP';
import { Amplify } from 'aws-amplify';
import { formFields, components } from './components/Auth';
import { Authenticator, Flex, useAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';

import { HeaderMod } from './components/HeaderMod';
import { FooterMod } from './components/FooterMod';
import { SideNavMod } from './components/SideNavMod';
import config from './amplifyconfiguration.json';
import { Hub } from 'aws-amplify/utils';

import '@aws-amplify/ui-react/styles.css';
import BugReport from './pages/BugReport';
import ViewRegistration from './pages/ViewRegistration';
import { dynamoAPI } from './data/dsaFetch';
import UsecasePage from './pages/UsecasePage';
import ApiPage from './pages/ApiPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ProfilePage from './pages/ProfilePage';

// Define loader here or import it from another file

const hideLoader = () => {
	const spinner = document.querySelector('.spinner-container');
	spinner.classList.add('loader--hide');
};


Amplify.configure(config);

function capture_logs() {
	var method;
	var noop = function () { };
	var methods = [
		'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
		'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
		'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
		'timeStamp', 'trace', 'warn'
	];
	var length = methods.length;
	var console = (window.console = window.console || {});

	// Array to store log messages
	var logEntries = [];

	function captureLog(method, args) {
		var timestamp = new Date().toISOString();
		var logMessage = `[${timestamp}] [${method}] ${Array.from(args).join(' ')}`;
		logEntries.push(logMessage);
	}

	while (length--) {
		method = methods[length];

		// Only stub undefined methods.
		if (!console[method]) {
			console[method] = noop;
		} else {
			(function (originalMethod) {
				console[method] = function () {
					captureLog(method, arguments);
					originalMethod.apply(console, arguments);
				};
			})(console[method]);
		}
	}

	if (Function.prototype.bind) {
		window.log = Function.prototype.bind.call(console.log, console);
	} else {
		window.log = function () {
			Function.prototype.apply.call(console.log, console, arguments);
		};
	}

	// Expose logEntries to the global scope for later use
	window.getLogEntries = function () {
		return logEntries;
	};
}

if (process.env.NODE_ENV == "production") {
	capture_logs();
} else {
	window.getLogEntries = function () {
		return ["TEST LOGS", "second log"];
	};
}

export const SessionContext = React.createContext(null);
const queryClient = new QueryClient();

export default function App() {
	// const { user } = useAuthenticator((context) => [context.user]);
	useEffect(hideLoader, []);
	const [session, setSession] = useState(null);
	useEffect(() => {
		console.log("Got session: ", session);
	}, [session]);
	const [isLoading, setIsLoading] = useState(true);
	const [isAdmin, setIsAdmin] = useState(false);
	const [isC4BH, setIsC4BH] = useState(false);
	// Debounce the filter to avoid rapid filtering during typing
	let session_timeout_id;
	const debounce = (func, delay) => {
		return (...args) => {
			clearTimeout(session_timeout_id);
			session_timeout_id = setTimeout(() => func(...args), delay);
		};
	};

	const checkSession = async () => {
		if (session != null) {
			setIsLoading(false);
			return;
		}
		try {
			const [sess, attrs] = await Promise.all([fetchAuthSession({forceRefresh: true}), fetchUserAttributes()]);
			if (!sess)
				console.error("no session", sess);
			if (!attrs)
				console.log("no attrs: ", attrs);
			const dxf_id = attrs['custom:member_of_dxf'];
			if (dxf_id) {
				const dyn_res = await dynamoAPI(dxf_id);
				console.log("dynamo DB response: for ", dxf_id, dyn_res);
				const org_name = dyn_res[0].Participant_Name_PrimaryOrganization;
				setSession((prev) => { return { ...sess, ...attrs, organization: org_name }; });
			} else {
				setSession((prev) => ({ ...sess, ...attrs }));
			}

		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};
	const debounce_checkSession = debounce(() => { checkSession(); }, 700);

	useEffect(() => {
		//console.log("AdminPage: Current session object:", session);
		if (!session)
			return;
		const idToken = session.tokens.idToken;
		if (!idToken || !idToken.payload) {
			console.error("AdminPage: Session token or payload is missing.");
			// navigate('/');
			return;
		}

		const groups = idToken.payload['cognito:groups'];

		if (!groups) {
			console.error("No groups found in session payload.");
			return;
		}

		console.log("groups: ", groups);

		setIsAdmin(groups.includes('dxfAdmin'));
		setIsC4BH(groups.includes('c4bhAdmin'));
	}, [session]);

	useEffect(() => { }, []);

	useEffect(() => {
		checkSession();
		Hub.listen('auth', ({ payload }) => {
			switch (payload.event) {
				case 'signedIn':
					console.log("user has been signedIn successfully.", payload);
					checkSession();
					break;
				case 'tokenRefresh':
					console.log('auth tokens has been refreshed.', payload);
					break;
				case 'tokenRefresh_failure':
					console.error('failure while refreshing auth tokens.', payload);
					break;
			}
		});
	}, []);
	return (
		<QueryClientProvider client={queryClient}>
			<Router>
				<Authenticator formFields={formFields} components={components}>
					<RouteLogger />
					<SessionContext.Provider value={{ session, isLoading, setIsLoading, checkSession, isAdmin, isC4BH }}>
						<HeaderMod style={{ zIndex: "200" }} />
						<SideNavMod style={{ zIndex: "200", width: "14rem", height: "calc(100vh - 78px)", overflowY: "auto" }} />
						<div style={{ position: "relative", top: "78px", display: "flex", flexDirection: "column" }}>
							<div style={{ display: "flex", flexDirection: "row", minHeight: "calc(-78px + 100vh)", alignItems: "stretch", justifyContent: "center" }}>
								<div id='testNav' style={{ zIndex: "10", flexShrink: "0", width: 'var(--side-nav)' }}></div>
								<div style={{ display: "inline-flex", flexDirection: "column", zIndex: "100", width: "100%", minHeight: "calc(-78px + 100vh)", maxWidth: 'calc(100vw - var(--side-nav))' }}>
									<Routes style={{ flexGrow: "1" }}>
										<Route path="/" element={<MainPage />} />
										<Route path="/mirth" element={<Mirth />} />
										<Route path="/Networking" element={<Networking />} />
										<Route path="/DxfRegistration/:id?" element={<DxfRegistration />} />
										<Route path="/viewRegistration/:participant_id" element={<ViewRegistration />} />
										<Route path="/profile/:participant_id" element={<ProfilePage />} />
										<Route path="/UseCaseOld" element={<UseCase />} />
										<Route path="/UseCase/:usecase_id?" element={<UsecasePage />} />
										<Route path="/SmileCDR" element={<SmileCDR />} />
										<Route path="/Background" element={<Background />} />
										<Route path="/OldMainPage" element={<OldMainPage />} />
										<Route path="/AdminPage" element={<AdminPage />} />
										{/* <Route path="/Graph" element={<Graph />} /> */}
										<Route path="/Dev" element={<DevPage />} />
										<Route path="/apis/:provider?" element={<ApiPage />} />
										<Route path="/ContactUs" element={<BugReport />} />
										<Route path="/FTP" element={<FTP />} />
									</Routes>
									<FooterMod style={{ zIndex: "400" }} />
								</div>
							</div>
						</div>
					</SessionContext.Provider>
				</Authenticator>
			</Router>
		</QueryClientProvider>
	);
}




