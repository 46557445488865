const Annotation = ({ node }) => {
	const node_data = node.data || node.getData();
	console.log("node: ", node, node_data);

	const nodeId = node_data?.Participant_ID ?? node?._index ?? "no id";
	const participantName =
		node_data?.Participant_Name_PrimaryOrganization ??
		node_data?.name ??
		"no name";
	
const customOrder = [
	"Participant_Name_SubordinateOrganization",
	"DxF_Program_Category",
	"Type",
	"Sub_Type",
	"State",
	"State Abbr.",
	"County",
	"City",
	"City Clean",
	"ZIP_Code",
	"ZIP_code Clean",
	"Request_for_Information",
	"Information_Delivery",
	"Requests_for_Notifications_of_ADT_Events",
	"Org_Level",
];

	const [customOrderedData, remainingData] = Object.entries(node_data || {}).reduce(
		([custom, others], [key, value]) => {
			if (key === "Participant_ID" || key === "Participant_Name_PrimaryOrganization" || key === "id") {
				return [custom, others];
			}
			if (customOrder.includes(key)) {
				custom.push([key, value]);
			} else {
				others.push([key, value]);
			}
			return [custom, others];
		},
		[[], []]
	);
	// Sort remaining fields alphabetically
	const sortedRemainingData = remainingData.sort(([keyA], [keyB]) =>
		keyA.localeCompare(keyB)
	);

	// Combine custom-ordered and alphabetically ordered remaining fields
	const sortedData = [
		...customOrderedData.sort(
			([keyA], [keyB]) =>
				customOrder.indexOf(keyA) - customOrder.indexOf(keyB)
		),
		...sortedRemainingData,
	];

	return (
		<div className="annotation">
			<div className="annotation-arrow" />
			<div className="annotation-header">
				{participantName} : {nodeId}
			</div>
			{sortedData.map(([key, value]) => {
				// Handle Neptune data format issues
				const v = value?.low
					? value.low
					: value?.day
					? value.toLocaleString()
					: value;
				return <AnnotationRow key={key} title={key} content={v} />;
			})}
		</div>
	);
};

const AnnotationRow = ({ title, content }) => {
	return (
		<div className="annotation-row">
			<div className="annotation-column">
				<b>{title}: </b>
			</div>
			<div>{content}</div>
		</div>
	);
};

export default Annotation;